.sheet-metric {
  align-items: center;
  padding-top: var(--chakra-space-1);
  padding-bottom: var(--chakra-space-1);
  overflow: hidden;
  max-height: 500px;
  transition:
    max-height 0.3s linear,
    padding 0.3s ease,
    background-color 0.3s ease;

  + .sheet-metric:last-of-type {
    margin-bottom: var(--chakra-space-3);
  }

  &.hidden {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  &.invalid {
    background-color: var(--chakra-colors-red-50);
  }

  &:hover {
    background-color: var(--chakra-colors-gray-50);
  }

  .input-field {
    & > div {
      text-align: center;
      width: 100%;
    }
  }

  .input-field + .input-field {
    margin-left: var(--chakra-space-6);
  }

  p {
    white-space: nowrap;
  }

  .column {
    overflow: hidden;
    transition:
      width 0.3s ease,
      margin 0.3s ease;
    width: 100px;
    white-space: nowrap;

    &.hidden {
      width: 0;
      margin: 0;
    }

    * {
      writing-mode: initial;
    }

    .double-deck {
      input {
        height: calc(var(--input-height) * 0.75);
      }
    }

    input {
      border-width: 0;
      border-bottom: 1px solid var(--chakra-colors-gray-300);
      border-radius: 0;
      text-align: center;
      box-shadow: none;

      &:focus-visible {
        box-shadow: none;
      }

      &:read-only {
        border-width: 0;
      }
    }

    .pill {
      min-width: 40px;
    }

    &.failed {
      color: red;
    }
  }
}

.metrics-group {
  p {
    white-space: initial;
  }
}
