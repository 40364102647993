.subscription-plan {
  width: 100%;
  border-top: 8px solid var(--chakra-colors-primary-500) !important; /* !important to override Card's styles */
  padding: 0 var(--chakra-space-5);

  .chakra-badge {
    background-color: var(--chakra-colors-primary-500);
    color: white;
    padding: 1px var(--chakra-space-2);
    border-radius: 4px;
    font-size: var(--chakra-fontSizes-sm);
    font-weight: normal;
  }

  &.inactive {
    border-top: 8px solid var(--chakra-colors-gray-200) !important; /* !important to override Card's styles */

    .chakra-badge {
      background-color: var(--chakra-colors-gray-300);
    }
  }

  & > div {
    width: 100%;
  }

  hr {
    border-color: var(--chakra-colors-gray-300);
  }

  .header {
    padding: var(--chakra-space-2) 0;
  }

  .summary {
    padding: var(--chakra-space-2) 0;

    .renewal-date {
      color: var(--chakra-colors-gray-600);
    }

    .price {
      color: var(--chakra-colors-primary-800);
      font-size: var(--chakra-fontSizes-lg);
      font-weight: bold;
    }

    .warning {
      color: var(--chakra-colors-red-500);
      font-size: var(--chakra-fontSizes-sm);
      font-weight: normal;
    }
  }

  .description {
    white-space: pre-wrap;
    padding: var(--chakra-space-4) 0;
  }
}
